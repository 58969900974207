import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import PartnerSearch from "./PartnerSearch";
import PartnerSearchResult from "./PartnerSearchResult";
import Paging from "../../layout/Paging";

import { AddOptionToString } from '../../../components/common/Common';

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import '../../admin_css/board.css';

/* 사업자 게시물리스트 */
function PartnerList({setMenuItem}) {
  const admin_region = localStorage.getItem("adminRegion");
  //사업자 게시물목록
  const [varPartnerLists,setPartnerLists] = useState([]);

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  const [varMaxNumPage, setMaxNumPage] = useState(0);

  //검색
  //const [varPartnerIndex, setPartnerIndex] = useState(0);
  const [varSearchData, setSearchData] = useState({
    sdate:null,
    edate:null,
    searchOption:'all',
    searchString:''
  });

  //파트너 게시물목록 읽어오기
  const getPartnerList = async () => {
    const sendData = {admin_region:admin_region,pageNum:varPageNum, pageSize:varPageSize, searchData:varSearchData};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_partner_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setTotalCount(datas.totalcount);
        setPartnerLists(datas.results);
        setMaxNumPage(datas.totalcount-(varPageNum-1)*varPageSize);
      } else {
        setTotalCount(0);
        setPartnerLists([]);
        setMaxNumPage(0);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('PartnerList');
    getPartnerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageNum(1); // 검색 조건이 변경될 때 페이지 번호를 1로 초기화
  }, [varSearchData]);

  //페이지 정보 표시 - 페이지가 변경되면
  useEffect(() => {
    getPartnerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum,varSearchData]);
  
  //사업자 게시물 항목삭제
  const onDeletePartnerHandler = async (e) => {
    //선택된 사업자 게시물 목록 추출
    let delListString = "";
    let delPartner = 0;
    varPartnerLists.map(element=>{
      if(element.checked) {
        delPartner++;
        delListString = AddOptionToString(element.jp_idx,delListString,',');
      }
      return null;
    });
    if(delPartner === 0) {
      alert('삭제할 사업자 게시물 항목이 선택되어 있지 않습니다.');
      return false;
    }
    if(!window.confirm('선택된 사업자 게시물을 삭제하시겠습니까?')) return false;

    const sendData = {stable:'jangter_partner', sprefix:'jp', delListString:delListString};
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_product_list`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //회원목록 재설정
        getPartnerList();
      }
    });
  }

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">사업자 게시물 관리</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>둥지/장터관리<img src={arrImage} alt=""/>이용 관리<img src={arrImage} alt=""/>사업자 게시물 관리
        </div>
      </div>
      <div className="content">
        <PartnerSearch varTotalCount={varTotalCount} varSearchData={varSearchData} setSearchData={setSearchData} />
        <PartnerSearchResult varMaxNumPage={varMaxNumPage} 
            varPartnerLists={varPartnerLists} setPartnerLists={setPartnerLists} onDeletePartnerHandler={onDeletePartnerHandler}/>
        <div className="paging" style={{ marginBottom: "20px" }}>
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </div>
      </div>
    </div>
  )
}

export default PartnerList;
