import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import { AddOptionToString } from '../../../components/common/Common';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import PaymentSearch from "./PaymentSearch";
import PaymentSearchResult from "./PaymentSearchResult";
import Paging from "../../layout/Paging";

//이용권결제관리
function PaymentVoucherList({setMenuItem}) {
  const admin_region = localStorage.getItem("adminRegion");
  //이용권결제목록
  const [varPaymentLists,setPaymentLists] = useState([]);

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  const [varMaxNumPage, setMaxNumPage] = useState(0);

  //검색
  //const [varPaymentIndex, setPaymentIndex] = useState(0);
  const [varSearchData, setSearchData] = useState({
    sdate:null,
    edate:null,
    searchProgress:0,
    searchOption:'all',
    searchString:''
  });

  //이용권결제목록 읽어오기
  const getPaymentList = async () => {
    const sendData = {paymentMode:1,admin_region:admin_region, pageNum:varPageNum, pageSize:varPageSize, searchData:varSearchData};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_payment_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setTotalCount(datas.totalcount);
        setPaymentLists(datas.results);
        setMaxNumPage(datas.totalcount-(varPageNum-1)*varPageSize);
      } else {
        setTotalCount(0);
        setPaymentLists([]);
        setMaxNumPage(0);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setPageNum(1); // 검색 조건이 변경될 때 페이지 번호를 1로 초기화
  }, [varSearchData]);

  useEffect(() => {
    setMenuItem('PaymentVoucherList');
    getPaymentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum,varSearchData]);

  //이용권결제항목삭제
  const onDeletePaymentHandler = async (e) => {
    //선택된 회원목록 추출
    let delListString = "";
    let delPayment = 0;
    varPaymentLists.map(element=>{
      if(element.checked) {
        delPayment++;
        delListString = AddOptionToString(element.ph_idx,delListString,',');
      }
      return null;
    });
    if(delPayment === 0) {
      alert('삭제할 이용권결제항목이 선택되어 있지 않습니다.');
      return false;
    }
    if(!window.confirm('선택된 이용권결제항목을 삭제하시겠습니까?')) return false;

    const sendData = {delListString: delListString};
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_payment_list`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //회원목록 재설정
        getPaymentList();
      }
    });
  }

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">이용권 결제 리스트</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>요금/이용권관리<img src={arrImage} alt=""/>이용권 결제 관리<img src={arrImage} alt=""/>이용권 결제 리스트
        </div>
      </div>
      <div className="content">
        <PaymentSearch varTotalCount={varTotalCount} varSearchData={varSearchData} setSearchData={setSearchData} />
        <PaymentSearchResult varMaxNumPage={varMaxNumPage} varPaymentLists={varPaymentLists} setPaymentLists={setPaymentLists} onDeletePaymentHandler={onDeletePaymentHandler}/>
        <div className="paging" style={{ marginBottom: "20px" }}>
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </div>
      </div>
    </div>
  )
}

export default PaymentVoucherList;
