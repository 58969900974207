import React from "react";
import { Link } from "react-router-dom";
import CONFIG from "../../../configs/Configs";
import axios from "axios";

import {ChangeDateStringOnlyDate,AddOptionToString,isNull,ResetCheckBoxes} from "../../../components/common/Common";

function AdminQuestionSearchResult({varBoardLists,setBoardLists,readBoardLists}) {
  const admin_mode = Number(localStorage.getItem("adminMode")); //1:운영자
  
  //목록의 모든 항목 선택하기
  const onCheckedAll = (e) => {
    const bChecked = e.currentTarget.checked;
    console.log(bChecked);
    clearBoardLists(bChecked);
  } 
  //체크박스 초기화
  const clearBoardLists = (bmode) => {
    const checkBoxes = document.querySelectorAll('.del-item');
    for(var i=0; i < checkBoxes?.length; i++) {
      checkBoxes[i].checked = bmode;
    }
    let copyLists = [...varBoardLists];
    for(var j=0; j < copyLists?.length; j++) {
      copyLists[j].checked = bmode;
    }
    setBoardLists(copyLists);
  }
  //목록상의 항목 선택하기
  const onCheckedElement = (e,board_idx) => {
    const bChecked = e.currentTarget.checked;
    const findIndex = varBoardLists.findIndex(element=>element.board_idx===board_idx);
    let copyLists = [...varBoardLists];
    copyLists[findIndex] = {...copyLists[findIndex],checked:bChecked};
    setBoardLists(copyLists);
    ResetCheckBoxes();
  } 

  //선택 항목 삭제
  const onDeleteBoardList = async(e) => {
    e.preventDefault();
    //삭제하기위해 선택된 항목 알아내기
    let delitems = 0;
    let boardIndexString = "";
    varBoardLists.map(element=>{
      if(element.checked) {
        delitems++;
        boardIndexString = AddOptionToString(element.board_idx,boardIndexString,',');
      }
      return "";
    });

    if (delitems === 0) {
      alert("삭제할 정보가 선택되어 있지 않습니다.");
      return;
    }
    if (!window.confirm(delitems + "개의 선택된 정보를 삭제합니까?")) return;

    const sendData = {mode:9,delitems: boardIndexString};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_board_list`, sendData).then((res) => {
      if (res.data.retvalue === 1) {
        clearBoardLists(false);
        readBoardLists();
      } else {
        window.alert(res.data.message);
      }
    });
  };


  return (
    <>          
      <div className="main-box2">
        <div className="btn-right">
          <Link to={`/Admin/AdminQuestionDetail/0`} className="btn-danger">추가</Link>
          {varBoardLists?.length>0 && admin_mode===1?<button onClick={(e)=>onDeleteBoardList(e)} className="btn line">삭제</button>:null}
        </div>
      </div>
      <div className="main-box">
        <table className="list">
          <colgroup>
            <col style={{width:"5%"}} />
            <col style={{width:"5%"}} />
            <col style={{width:"35%"}}/>
            <col style={{width:"10%"}}/>
            <col style={{width:"10%"}} />
            <col style={{width:"15%"}}/>
            <col style={{width:"10%"}}/>
            <col style={{width:"10%"}}/>
          </colgroup>
          <thead>
            <tr>
              <th className="board-list"><input type="checkbox" id="deleteItems" onChange={(e)=>onCheckedAll(e)} /></th>
              <th>번호</th>
              <th>제목</th>
              <th>권한</th>
              <th>작성자</th>
              <th>등록일자</th>
              <th>상태</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {varBoardLists?.length === 0?<tr><td colSpan={8}>등록된 내용이 없습니다.</td></tr>:
              varBoardLists.map((board, i) => (
              <tr key={'board_'+i}>
                <td className="board-list">
                  <input type="checkbox" className="del-item" id={"deleteItem_" + board.board_idx} defaultChecked={false} onChange={(e)=>onCheckedElement(e,board.board_idx)}/>
                </td>
                <td>{board.board_num}</td>
                <td className="ellipsis">{board.board_title}</td>
                <td className="ellipsis">
                  {board.mem_admin===1?"운영자"
                  :board.mem_admin===2?"부운영자"
                  :isNull(board.regname)?"지역관리자"
                  :board.regname}
                </td>
                <td className="ellipsis">{board.mem_name}</td>
                <td className="ellipsis">{ChangeDateStringOnlyDate(board.board_rdate)}</td>
                <td className="ellipsis">{board.board_status===1?"문의중":"답변완료"}</td>
                <td>
                  <Link to={`/Admin/AdminQuestionDetail/${board.board_idx}`} style={{width:"90px"}} className="btn line black">세부정보</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AdminQuestionSearchResult;