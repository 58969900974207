import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import { AddOptionToString,isObjEmpty } from '../../../components/common/Common';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import MemberSearch from "./MemberSearch";
import MemberSearchResult from "./MemberSearchResult";
import Paging from "../../layout/Paging";


function MemberLists({setMenuItem,varTermTitle,setTermTitle}) {
  let { memMode } = useParams();
  const admin_region = localStorage.getItem("adminRegion");
  //회원목록
  const [varMemberLists,setMemberLists] = useState([]);

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  const [varMaxNumPage, setMaxNumPage] = useState(0);

  //검색
  const searchingData = {sdate:null,edate:null,searchWarning:-1,searchOption:"all",searchString:''}
  const [varSearchData, setSearchData] = useState({});

  //회원목록 읽어오기
  const getMemberList = async () => {
    const sendData = {memMode: Number(memMode),admin_region:admin_region,pageNum: varPageNum,pageSize: varPageSize,searchData: varSearchData};
    console.log(sendData);

    await axios.post(`${CONFIG.SERVER_HOST}/_get_member_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setTotalCount(datas.totalcount);
        setMemberLists(datas.results);
        setMaxNumPage(datas.totalcount-(varPageNum-1)*varPageSize);
      }
    });
  }

  //페이지 구분 설정
  const resetPageMode = () => {
    setSearchData(searchingData);
    setPageNum(1);
    setTotalCount(0);
    let termTitle = "", termMenu = "";
    switch(Number(memMode)) {
      default:
      case 1: 
        termTitle = "일반회원관리";
        termMenu = "MemberListN";
        break;
      case 2: 
        termTitle = "부동산파트너관리";
        termMenu = "MemberListC";
        break;
      case 3: 
        termTitle = "사업자파트너관리";
        termMenu = "MemberListL";
        break;
      case 0: 
        termTitle = "탈퇴회원관리";
        termMenu = "MemberListR";
        break;
      case -1: 
        termTitle = "차단회원관리";
        termMenu = "MemberListB";
        break;
    }
    setTermTitle(termTitle);
    setMenuItem(termMenu);
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    resetPageMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memMode]);

  useEffect(() => {
    setPageNum(1); // 검색 조건이 변경될 때 페이지 번호를 1로 초기화
  }, [varSearchData]);

  useEffect(() => {
    if(!isObjEmpty(varSearchData)) getMemberList();
    console.log("varSearchData: ",varSearchData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memMode,varPageNum,varSearchData]);

  //회원삭제
  const onDeleteMemberHandler = async (e,istate) => {
    let stitle = "";
    if(istate === -1) stitle = "차단";
    else if(istate === 1) stitle = "차단해제";
    else stitle = "삭제";

    //선택된 회원목록 추출
    let delListString = "";
    let delMember = 0;
    varMemberLists.map(element=>{
      if(element.checked) {
        delMember++;
        delListString = AddOptionToString(element.mem_idx,delListString,',');
      }
      return null;
    });
    if(delMember === 0) {
      alert(`${stitle}할 회원이 선택되어 있지 않습니다.`);
      return false;
    }
    if(!window.confirm(`선택된 회원들을 ${stitle}하시겠습니까?`)) return false;

    const sendData = {stateValue:istate,delListString: delListString};
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_member_list`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //회원목록 재설정
        getMemberList();
      }
    });
  }

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">{varTermTitle||''}</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>회원 관리<img src={arrImage} alt=""/>{varTermTitle||''}
        </div>
      </div>
      <div className="content">
        <MemberSearch memMode={Number(memMode)} varTotalCount={varTotalCount} varSearchData={varSearchData} setSearchData={setSearchData} />
        <MemberSearchResult memMode={memMode} varMaxNumPage={varMaxNumPage} 
              varMemberLists={varMemberLists} setMemberLists={setMemberLists} onDeleteMemberHandler={onDeleteMemberHandler}/>
        <div className="paging" style={{ marginBottom: "20px" }}>
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </div>
      </div>
    </div>
  )
}

export default MemberLists;
