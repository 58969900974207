/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"; 
import { Link,useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";

import logoImage from "../../images/realtor/logo.png";
import infoImage1 from "../../images/realtor/info_img01.png";
import infoImage2 from "../../images/realtor/info_img02.png";
import serviceImage1 from "../../images/realtor/service_01.png";
import serviceImage2 from "../../images/realtor/service_02.png";
import serviceImage3 from "../../images/realtor/service_03.png";

import closeImage from "../../images/realtor/close.png" ;

import "../../css/realtor.css";

const RealEstateAgent = () => {
  const navigate = useNavigate();
  const [varLoginEmail,setLoginEmail] = useState('');
  const [varLoginPwd,setLoginPwd] = useState('');


  //팝업창 - 로그인 페이지
  const onPopupLoginHandler = (e,bmode) => {
    if(bmode) {
      $(".login_pop").removeClass('close');
      $(".login_pop").slideDown();
    } else {
      $(".login_pop").addClass('close');
      $(".login_pop").hide();
    }
  }
  const onInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    if(name === "mem_email") {
      setLoginEmail(value);
    } else {
      setLoginPwd(value);
    }
  }
  const onLoginProgressHandler = async(e) => {
    if(varLoginEmail === "") {
      alert('아이디(이메일)을 먼저 입력하세요.'); return false;
    } else if(varLoginPwd === "") {
        alert('비밀번호를 먼저 입력하세요.'); return false;
    }
    const sendData = {mem_email:varLoginEmail,mem_pwd:varLoginPwd};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_login_realtor`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        const members = datas.results[0];
        if(members.mcm_approval === 0) {
          alert('아직 운영자로부터 승인을 받지 못했습니다.\n조금 더 기다리시거나 운영자에게 직접 연락하세요.'); return false;
        }
        localStorage.setItem("mem_idx", members.mem_idx);
        localStorage.setItem("mcm_idx", members.mcm_idx);
        localStorage.setItem("mem_mode", members.mem_mode);
        localStorage.setItem("mem_nickname", members.mcm_name);
        localStorage.setItem("mcm_name", members.mcm_name);
        localStorage.setItem("memberLoginState", 1);
        localStorage.setItem("mem_admin", 0);
        const bcount = datas.bcount;
        //console.log('bcount',bcount);
        let bizString = "";
        for(var i=0; i < bcount; i++) {
          if(bizString === "") bizString = "0";
          else bizString += ",0";
        }
        //console.log('bizString',bizString);
        localStorage.setItem("businessOptions", bizString);
        navigate('/RealEstate');
      } else {
        alert(datas.message);
      }
    });
  }

  return (
    <>
      <header id="header_realtor">
        <button className="logo"><img src={logoImage} alt=""/></button>
        <button className="login" onClick={e=>onPopupLoginHandler(e,true)}>부동산 로그인</button>
      </header>
      <div className="main_con">
        <div className="main_banner">
          <div className="banner_left">
            <p>
              <span>둥지장터</span>는 사용자가 원하는 지역, 가격대, 면적 등<br />
              다양한 검색 조건에 따라 매물을 쉽게 검색하고<br />
              인테리어, 디자인, 세무 관련 등<br />
              생활 전반에 관련한 정보 제공하는 생활 밀착형 서비스입니다.
            </p>
            <Link to={""}>앱 다운로드 받기</Link>
          </div>
          <div className="banner_right"><img src={logoImage} alt=""/></div>
        </div>
        <div className="con_01 con_realtor">
          <p className="tit">
            <span>둥지</span>는 다양한 매물을 검색하고 매물에 대해 자유롭게 채팅할 수 있습니다.
          </p>
          <div className="img_box"><img src={infoImage1} alt=""/></div>
        </div>
        <div className="con_02 con_realtor">
          <p className="tit">
            <span>장터</span>에서는 여러 입점 업체를 테마에 맞게 분류하여 사용자들에게 소개하며,<br />
            더불어 사용자들의 커뮤니티도 제공하고 있습니다.
          </p>
          <div className="img_box"><img src={infoImage2} alt=""/></div>
        </div>
        <div className="con_03 con_realtor">
          <p className="tit">둥지장터에서 다양한 서비스를 이용하실 수 있습니다.</p>
          <div className="img_wrap">
            <div className="img">
              <img src={serviceImage1} alt=""/>
              <p>다양한 사용자</p>
            </div>
            <div className="img">
              <img src={serviceImage2} alt=""/>
              <p>자유로운 소통</p>
            </div>
            <div className="img">
              <img src={serviceImage3} alt=""/>
              <p>업체 광고 효과</p>
            </div>
          </div>
        </div>
        <div className="con_04 con">
          <p>둥지장터를 이용하고 다양한 정보를 확인해보세요!</p>
          <Link to={""}>앱 다운로드 받기</Link>
        </div>
      </div>
      <footer id="footer">
        <p className="c_name">(주) 케이지코리아</p>
        <p className="c_info">
          대표: 전명근 <br/>
          사업자 등록번호: 261-45-00590 | 통신판매업신고번호 : 제0000호강남00000 <br/>
          주소: [06253]서울특별시 강남구 도곡로3길 25 삼성애니텔 1층 105호 | 고객센터 : 02-555-3433 <br/>
        </p>
        <p className="copy">COPYRIGHT 2022 둥지 ALL RIGHTS RESERVED.</p>
      </footer>

      {/* 로그인팝업 */}
      <div className="login_pop close">
        <div className="login_wrap">
          <p>로그인</p>
          <div className="close" onClick={e=>onPopupLoginHandler(e,false)}><img src={closeImage} alt=""/></div>
          <div className="login_input">
            <span>아이디</span>
            <input type="text" name="mem_email" value={varLoginEmail} placeholder="아이디를 입력하세요." onChange={e=>onInputHandler(e)}/>
          </div>
          <div className="login_input">
            <span>비밀번호</span>
            <input type="password" name="mem_pwd" value={varLoginPwd} placeholder="비밀번호를 입력하세요." onChange={e=>onInputHandler(e)}/>
          </div>
          <div className="btn_realtor">
            <button className="login_btn" type="button" onClick={e=>onLoginProgressHandler(e)}>로그인</button>
          </div>
          <div className="s-menu">
            <Link to="/Member/FindID">아이디 찾기</Link>
            <Link to="/Member/FindPWD">비밀번호 찾기</Link>
          </div>
        </div>
      </div>
    </>
  );
}
export default RealEstateAgent;