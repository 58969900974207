/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { ASC } from '../common/Common';

import closeButton from "../../images/icon_close.png";

const SelectRegion = ({varPopupSearchRegion,onPopupRegionHandler,varSearchRegionList,setSearchRegionList,varIsPremium}) => {
  //지역목록변수
  const [varRegionList,setRegionList] = useState([]);
  const [varInitialRegionList,setInitialRegionList] = useState([]);
  const [varSelectedRegionList,setSelectedRegionList] = useState([]);
  const [varSelectedRegcodeList,setSelectedRegcodeList] = useState([]);

  const [varSiDoList,setSiDoList] = useState([]);
  const [varSiGunGuList,setSiGunGuList] = useState([]);
  const [varDongRiList,setDongRiList] = useState([]);

  const [varCurrentSiDoCode,setCurrentSiDoCode] = useState({});
  const [varCurrentSiGunGuCode,setCurrentSiGunGuCode] = useState({});
  const [varCurrentDongRiCode,setCurrentDongRiCode] = useState([]);

  //지역코드 정보 읽기
  const getRegionList = async() => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_region_list`).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //검색된 전체 지역 목록
        setRegionList(datas.results);
        //시/도 목록
        const SiDoList = datas.results.filter(el=>el.regcode.substr(2,8)==='00000000');
        setSiDoList(SiDoList);
      }
    });
  }

  //페이지 정보 표시 - 최초 
  useEffect(() => {
    let newString = [];
    //선택되어 있는 지역 배열
    //console.log(varSearchRegionList);
    for(var i=0; i < varSearchRegionList.length; i++) {
      newString.push(varSearchRegionList[i].regcode);
    }
    //console.log(newString);
    setSelectedRegcodeList(newString);  //지역코드만 배열로
    setSelectedRegionList(varSearchRegionList); //선택된 지역코드,지역명의 배열
    setInitialRegionList(varSearchRegionList);  //현재 선택된 지역 배열 저장 - 팝업창호출시

    setCurrentSiDoCode({regcode:'',regname:'시/도 선택'});
    setCurrentSiGunGuCode({regcode:'',regname:'시/군/구 선택'});
    setSiGunGuList([]);
    setDongRiList([]);

    //지역코드 전체 읽기
    getRegionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPopupSearchRegion]);

  //시/군/구 목록 가져오기
  const getSiGunGuRegionList = (sregion) => {
    //console.log(sregion);
    const prefixCode = sregion.regcode.substr(0,2);
    //시/군/구 목록
    const sGunGuList = varRegionList.filter(element=>element.regcode.substr(0,2)===prefixCode && element.regcode.substr(5,5)==='00000' && element.regcode.substr(2,8)!=='00000000');
    //쏘팅
    sGunGuList.sort(ASC('regname',true));
    setSiGunGuList(sGunGuList);
  }
  //동/리 목록 가져오기
  const getDongRiRegionList = (sregion) => {
    const prefixCode = sregion.regcode.substr(0,5);
    //동/리 목록
    const sDongRiList = varRegionList.filter(element=>element.regcode.substr(0,5)===prefixCode && element.regcode.substr(5,5)!=='00000');
    //쏘팅
    sDongRiList.sort(ASC('regname',true));
    setDongRiList(sDongRiList);
    console.log(sDongRiList);
  }

  //시도,시군구 산택
  const onSelectedRegionHandler = (e,ino) => {
    const { value } = e.currentTarget;
    const sname = e.currentTarget.options[e.currentTarget.selectedIndex].text;
    const newData = {regcode:value,regname:sname};

    if(ino === 1) { //시/도 선택시
      setCurrentSiDoCode(newData);
      if(value === "") {  
        setSiGunGuList([]);
      } else if(value === "0000000000") {
        let copyList = [];
        copyList = copyList.concat(newData);
        //console.log(copyList);
        setSelectedRegionList(copyList);
        setCurrentSiGunGuCode({regcode:'',regname:'시/군/구 선택'});
        setSiGunGuList([]);
        setDongRiList([]);
      } else {
        //시군구청 목록 가져오기
        getSiGunGuRegionList(newData);
      }
      setDongRiList([]);
    } else {  //시/군/구 선택시
      setCurrentSiGunGuCode(newData);
      //document.getElementById('dongri_0').checked  = false;
      if(value === "") {  
        setDongRiList([]);
        //만약 시.도가 검색에 있으면 삭제
        let exceptCurrentDongList = varSelectedRegionList.filter(element=>element.regcode!==newData.regcode);
        setSelectedRegionList(exceptCurrentDongList);
        //선택표시를 위해
        let exceptCurrentDongcodeList = varSelectedRegcodeList.filter(element=>element!==newData.regcode);
        setSelectedRegcodeList(exceptCurrentDongcodeList);
      } else if(value === varCurrentSiDoCode.regcode) {  //시/군/구 전체 선택시
        if(sname === "전체") {
          newData.regname = varCurrentSiDoCode.regname;
        }
        setDongRiList([]);
        //시.도 검색에 추가
        let exceptCurrentDongList = varSelectedRegionList.filter(element=>element.regcode!==newData.regcode);
        setSelectedRegionList(exceptCurrentDongList.concat(newData));
        //선택표시를 위해
        let exceptCurrentDongcodeList = varSelectedRegcodeList.filter(element=>element!==newData.regcode);
        setSelectedRegcodeList(exceptCurrentDongcodeList.concat(newData));
      } else {
        //동리목록 가져오기
        getDongRiRegionList(newData);
      }
    }
    //시도청/시군구가 바뀌면 동리선택배열 초기화
    setCurrentDongRiCode([]);
  }
  //동리 선택
  const onSelectDongRiHandler = (e,dongriObj) => {
    const { value } = e.currentTarget;
    console.log(value,dongriObj);
    const bmode = e.currentTarget.checked;

    if(value === varCurrentSiGunGuCode.regcode) {  //전체선택시
      //선택한 동이 있으면 제외
      const currentSigunguCode = varCurrentSiGunGuCode.regcode;
      for(var i=0; i < varSelectedRegionList?.length; i++) {
        var reg_code = varSelectedRegionList[i].regcode;
        if(reg_code.substr(0,5)===currentSigunguCode.substr(0,5) && reg_code.substr(5,5)!=="00000"){
          document.getElementById("dongri_"+reg_code).checked = false;
        }
      };
      const exceptCurrentDongList = varSelectedRegionList.filter(element=>element.regcode.substr(0,5)!==currentSigunguCode.substr(0,5));
      const exceptCurrentDongcodeList = varSelectedRegcodeList.filter(element=>element.substr(0,5)!==currentSigunguCode.substr(0,5));
      if(bmode) { //전체 선택시 
        //현재 선택배열에 시군구 추가
        setSelectedRegionList(exceptCurrentDongList.concat(varCurrentSiGunGuCode));
        setSelectedRegcodeList(exceptCurrentDongcodeList.concat(currentSigunguCode));
        console.log('1',exceptCurrentDongList.concat(varCurrentSiGunGuCode),exceptCurrentDongcodeList.concat(currentSigunguCode));
        //동리배열 초기화
        setCurrentDongRiCode([]);
      } else {  //전체 선택 취소시
        //시군구 제외
        setSelectedRegionList(exceptCurrentDongList);
        setSelectedRegcodeList(exceptCurrentDongcodeList);
        console.log('2',exceptCurrentDongList,exceptCurrentDongcodeList);
      }
    } else {  //동선택시
      if(bmode) {  //선택되면
        //전체가 선택되어 있었으면 시군구 제외
        const currentSigunguCode = varCurrentSiGunGuCode.regcode;
        const exceptCurrentDongList = varSelectedRegionList.filter(element=>element.regcode!==currentSigunguCode);
        const exceptCurrentDongcodeList = varSelectedRegcodeList.filter(element=>element.regcode!==currentSigunguCode);
        //전체 선택 취소
        document.getElementById('dongri_0').checked = false;

        //제한 갯수 파악
        if(exceptCurrentDongList?.length === 5) {
          alert('최대 5개까지 선택이 가능합니다.'); 
          e.currentTarget.checked = false;
          return false;
        }

        //추가할 지역 배열 생성
        const addRegionData = {regcode:value,regname:dongriObj.regname};
        //현재 시군구청내 선택된 동리
        setCurrentDongRiCode(varCurrentDongRiCode.concat(addRegionData));
        //검색 지역 배열에 추가 (동/리의 경우)
        setSelectedRegionList(exceptCurrentDongList.concat(addRegionData));
        setSelectedRegcodeList(exceptCurrentDongcodeList.concat(value));
        console.log('3',exceptCurrentDongList.concat(addRegionData),exceptCurrentDongcodeList.concat(value));
      } else {  //동선택취소시
        const removeList1 = varCurrentDongRiCode.filter(element=>element.regcode !== value);
        setCurrentDongRiCode(removeList1);
        //검색 지역 배열에서 제외 (동/리의 경우)
        const removeList2 = varSelectedRegionList.filter(element=>element.regcode !== value);
        setSelectedRegionList(removeList2);
        const removeList3 = varSelectedRegcodeList.filter(element=>element !== value);
        setSelectedRegcodeList(removeList3);
        console.log('4',removeList2,removeList3);
      }
    }
  }
  //검색시작
  const onSearchingRegionHandler = (e) =>{
    if(varCurrentSiDoCode.regcode === "0000000000") { //전국
      //setSelectedRegionList([]);
      //setSearchRegionList([]);
      //console.log(varSelectedRegionList);
      let newData = {regcode: '0000000000', regname: '전국'};
      let copyList = [];
      copyList = copyList.concat(newData);
      setSearchRegionList(copyList);
    }
    else {
      if(varSelectedRegionList?.length === 0) {
        alert('검색을 위해 선택한 지역이 없습니다.'); 
        return false;
      }
      if(varSelectedRegionList?.length > 5) {
        alert('최대 5개까지 선택이 가능합니다.');  
        return false;
      }
      //전국포함유무 확인
      let copyList = varSelectedRegionList.filter(el=>el.regcode !== '0000000000');
      //팝업창에서 변경한 지역선택 배열을 전역배열에 지정
      setSearchRegionList(copyList);
    }
    onPopupRegionHandler(e,-1);
  }
  //지역선택 취소
  const onCancelRegionHandler = (e) => {
    setSearchRegionList(varInitialRegionList);
    onPopupRegionHandler(e,false);
  }

  return (
    <div className="layer-pop full_pop" style={varPopupSearchRegion?{display:"block"}:{display:"none"}}>
      <div className="pop_head">
        <div className="layer_tit">지역을 선택해주세요</div>
        <div className="close_layer top_close_btn"><img src={closeButton} alt="" onClick={e=>onPopupRegionHandler(e,false)}/></div>
      </div>
      <div className="layer_inner">
        <div className="sel_wrap">
          <select value={varCurrentSiDoCode.regcode} onChange={e=>onSelectedRegionHandler(e,1)}>
            <option value="">시/도 선택</option>
            <option value="0000000000">전국</option>
            {varSiDoList.map((sido,i)=>(
              <option key={'sido_'+i} value={sido.regcode}>{sido.regname}</option>
            ))}
          </select>
          <select value={varCurrentSiGunGuCode.regcode} onChange={e=>onSelectedRegionHandler(e,2)}>
            <option value="">시/군/구 선택</option>
            {varSiGunGuList?.length===0?null:<option value={varCurrentSiDoCode.regcode}>전체</option>}
            {varSiGunGuList.map((sigungu,i)=>(
              <option key={'sigungu_'+i} value={sigungu.regcode}>{sigungu.regname}</option>
            ))}
          </select>
        </div>
          {varIsPremium===true?
          <div className="pop_subtit">동 설정&nbsp;&nbsp;&nbsp;<span className="gray_txt">지역을 선택해주세요.</span></div>
          :
          <div className="pop_subtit">동 설정&nbsp;&nbsp;&nbsp;<span className="gray_txt">지역은 최대 5개까지 선택 가능합니다.</span></div>
          }
          <ul className="scroll-wrap">
            {varDongRiList?.length === 0? null:
              <li className="terms_chks">
                <input type="checkbox" className="chk1" id={"dongri_0"} value={varCurrentSiGunGuCode.regcode} 
                  checked={varSelectedRegcodeList.includes(varCurrentSiGunGuCode.regcode)?true:false} onChange={e=>onSelectDongRiHandler(e,"")}/>
                <i></i>
                <label htmlFor={"dongri_0"}>전체</label>
              </li>
            }
            {varDongRiList.map((dongri,i)=>(
              <li key={'dongri_'+i} className="terms_chks">
                <input type="checkbox" className="chk1" id={"dongri_"+dongri.regcode} value={dongri.regcode||""} 
                checked={varSelectedRegcodeList.includes(dongri.regcode)?true:false} onChange={e=>onSelectDongRiHandler(e,dongri)}/>
                <i></i>
                <label htmlFor={"dongri_"+dongri.regcode}>{dongri.regname}</label>
              </li>
            ))}
          </ul>
      </div>
      <div className="btn_bottom">
        <button className="btn_style_no close_layer" onClick={e=>onCancelRegionHandler(e)}>취소</button>
        <button className="btn_style_yes" onClick={e=>onSearchingRegionHandler(e)}>설정</button>
      </div>
    </div>
  );
}
export default SelectRegion;