import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import DoongjiSearch from "./DoongjiSearch";
import DoongjiSearchResult from "./DoongjiSearchResult";
import Paging from "../../layout/Paging";

import { AddOptionToString } from '../../../components/common/Common';

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import '../../admin_css/board.css';

/* 둥지매물리스트 */
function DoongjiList({setMenuItem}) {
  const admin_region = localStorage.getItem("adminRegion");
  //둥지매물목록
  const [varDoongjiLists,setDoongjiLists] = useState([]);

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  const [varMaxNumPage, setMaxNumPage] = useState(0);

  //검색
  //const [varDoongjiIndex, setDoongjiIndex] = useState(0);
  const [varSearchData, setSearchData] = useState({
    sdate:null,
    edate:null,
    searchOption:'all',
    searchString:''
  });

  //둥지매물목록 읽어오기
  const getDoongjiList = async () => {
    const sendData = {admin_region:admin_region,pageNum:varPageNum, pageSize:varPageSize, searchData:varSearchData};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_doongji_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setTotalCount(datas.totalcount);
        if(datas.results?.length > 0) {
          setDoongjiLists(datas.results);
          setMaxNumPage(datas.totalcount-(varPageNum-1)*varPageSize);
        } else {
          setDoongjiLists([]);
          setMaxNumPage(0);
        }
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('DoongjiList');
    getDoongjiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageNum(1); // 검색 조건이 변경될 때 페이지 번호를 1로 초기화
  }, [varSearchData]);

  //페이지 정보 표시 - 페이지가 변경되면
  useEffect(() => {
    getDoongjiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum,varSearchData]);
  
  //둥지매물 항목삭제
  const onDeleteDoongjiHandler = async (e) => {
    //선택된 회원목록 추출
    let delListString = "";
    let delDoongji = 0;
    varDoongjiLists.map(element=>{
      if(element.checked) {
        delDoongji++;
        delListString = AddOptionToString(element.rp_idx,delListString,',');
      }
      return null;
    });
    if(delDoongji === 0) {
      alert('삭제할 둥지매물 항목이 선택되어 있지 않습니다.');
      return false;
    }
    if(!window.confirm('선택된 둥지매물 항목을 삭제하시겠습니까?')) return false;

    const sendData = {stable:'doongji', sprefix:'rp', delListString:delListString};
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_product_list`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //회원목록 재설정
        getDoongjiList();
      }
    });
  }

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">둥지 매물 리스트</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>둥지/장터관리<img src={arrImage} alt=""/>이용 관리<img src={arrImage} alt=""/>둥지 매물 리스트
        </div>
      </div>
      <div className="content">
        <DoongjiSearch varTotalCount={varTotalCount} varSearchData={varSearchData} setSearchData={setSearchData} />
        <DoongjiSearchResult varMaxNumPage={varMaxNumPage} 
            varDoongjiLists={varDoongjiLists} setDoongjiLists={setDoongjiLists} onDeleteDoongjiHandler={onDeleteDoongjiHandler}/>
        <div className="paging" style={{ marginBottom: "20px" }}>
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </div>
      </div>
    </div>
  )
}

export default DoongjiList;
